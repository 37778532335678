import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgo360, LazySvgoAromaBlend, LazySvgoArrowRight, LazySvgoBack, LazySvgoCheck, LazySvgoChevronLeft, LazySvgoChevronTop, LazySvgoCloseThin, LazySvgoClose, LazySvgoDeleteFile, LazySvgoEdit, LazySvgoEyeClosed, LazySvgoEyeOpen, LazySvgoHelp, LazySvgoIsSuccess, LazySvgoLogoLeft, LazySvgoNotSuccess, LazySvgoSelectArrow, LazySvgoSelectClear, LazySvgoSliderArrow, LazySvgoSweetTipping } from '#components'
const lazyGlobalComponents = [
  ["Svgo360", LazySvgo360],
["SvgoAromaBlend", LazySvgoAromaBlend],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoBack", LazySvgoBack],
["SvgoCheck", LazySvgoCheck],
["SvgoChevronLeft", LazySvgoChevronLeft],
["SvgoChevronTop", LazySvgoChevronTop],
["SvgoCloseThin", LazySvgoCloseThin],
["SvgoClose", LazySvgoClose],
["SvgoDeleteFile", LazySvgoDeleteFile],
["SvgoEdit", LazySvgoEdit],
["SvgoEyeClosed", LazySvgoEyeClosed],
["SvgoEyeOpen", LazySvgoEyeOpen],
["SvgoHelp", LazySvgoHelp],
["SvgoIsSuccess", LazySvgoIsSuccess],
["SvgoLogoLeft", LazySvgoLogoLeft],
["SvgoNotSuccess", LazySvgoNotSuccess],
["SvgoSelectArrow", LazySvgoSelectArrow],
["SvgoSelectClear", LazySvgoSelectClear],
["SvgoSliderArrow", LazySvgoSliderArrow],
["SvgoSweetTipping", LazySvgoSweetTipping],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
