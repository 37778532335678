<script setup>
import { VueFinalModal } from 'vue-final-modal';
import { useVModel } from '@vueuse/core';

import UiButtonClose from '@/components/ui/button/UiButtonClose.vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  fullWidthMobile: {
    type: Boolean,
    default: false,
  },
  positionButtonOutsideRight: {
    type: Boolean,
    default: false,
  },
  themeButtonClose: {
    type: String,
    default: undefined,
    validator(theme) {
      return ['dark'].includes(theme);
    },
  },
  actionButtonClose: {
    type: Function,
    default: undefined,
  },
});

const emit = defineEmits(['update:modelValue']);

const localVModel = useVModel(props, 'modelValue', emit);

function closePopup() {
  if (props.actionButtonClose) {
    props.actionButtonClose?.();
  }

  localVModel.value = false;
}
</script>

<template>
  <VueFinalModal
    v-model="localVModel"
    teleport-to="body"
    class="popup-center"
    :background="'non-interactive'"
    :content-class="'popup-center__inner'"
    overlay-class="popup-center__overlay"
    :swipe-to-close="'none'"
    :overlay-transition="'vfm-fade'"
    :content-transition="'vfm-slide-dow-slow'"
    :class="{
      'popup-center--full-width-mobile': props.fullWidthMobile,
      'popup-center--position-button-outside-right':
        props.positionButtonOutsideRight,
    }"
  >
    <div class="popup-center__content">
      <UiButtonClose
        class="popup-center__button"
        :theme="themeButtonClose"
        @click="closePopup"
      />

      <slot :close="closePopup" />
    </div>
  </VueFinalModal>
</template>

<style lang="scss">
.vfm-slide-dow-slow {
  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: translateY(30%);
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.5s;
  }
}

.popup-center {
  $parent: &;

  display: flex;

  &__inner {
    @include hide-scroll;

    width: fit-content;
    max-height: 100%;
    margin: auto;
    overflow: hidden auto;
  }

  &__content {
    position: relative;
  }

  &__overlay {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(12px);
  }

  &__button {
    position: absolute;
    top: em(24);
    right: em(24);
    z-index: 1;

    @include media-breakpoint-down(md) {
      top: em(20);
      right: em(20);
    }
  }

  &--full-width-mobile {
    @include media-breakpoint-down(sm) {
      #{$parent} {
        &__inner {
          width: 100%;
        }
      }
    }
  }
}
</style>
