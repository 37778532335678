<script setup>
import UiLinkTag from '@/components/ui/UiLinkTag.vue';

const props = defineProps({
  to: {
    type: String,
    default: undefined,
  },
  target: {
    type: String,
    default: undefined,
  },
  isStaticByDefault: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: undefined,
  },
  text: {
    type: String,
    default: undefined,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: undefined,
    validator(theme) {
      return [
        'white',
        'second',
        'small',
        'link',
        'second-white',
        'link-big',
        'white-small',
        'white-footer',
      ].includes(theme);
    },
  },
});

const emit = defineEmits(['click']);

const emitClick = (e) => {
  emit('click', e);
};
</script>
<template>
  <UiLinkTag
    :to="props.to"
    :target="props.target"
    :type="props.type"
    :is-static-by-default="props.isStaticByDefault"
    class="ui-button"
    :is-disabled="isDisabled"
    :class="{
      [`ui-button--theme--${theme}`]: theme,
      'ui-button--disabled': isDisabled,
    }"
    @click="emitClick"
  >
    <slot>
      <span v-if="text" class="ui-button__font">{{ text }}</span>
    </slot>
  </UiLinkTag>
</template>

<style scoped lang="scss">
.ui-button {
  $parent: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: em(50);
  padding: em(10) em(20) em(12);
  color: $color-black-100;
  text-align: center;
  background-color: transparent;
  backdrop-filter: blur(0);
  border: 1px solid $color-black-100;
  border-radius: em(44);
  transition: $time-default ease-out;
  transition-property: background-color, opacity, backdrop-filter;

  @include hover {
    background-color: rgba($color-grey-100, 0.15);
    backdrop-filter: blur(5px);
  }

  @include media-breakpoint-down(lg) {
    min-height: em(36);
    padding: em(8) em(10) em(8);
  }

  @include media-breakpoint-down(sm) {
    min-height: em(50);
    padding: em(10) em(20) em(8);
  }

  &__font {
    @include button;

    @include media-breakpoint-down(lg) {
      font-size: em(11);
    }

    @include media-breakpoint-down(sm) {
      @include button-mob;
    }
  }

  &--disabled {
    pointer-events: none;
    touch-action: none;
    opacity: 0.4;
  }

  &--theme {
    &--white {
      color: $color-white;
      border-color: $color-white;
    }

    &--white-small {
      color: $color-white;
      border-color: $color-white;

      @include media-breakpoint-down(lg) {
        min-height: em(36);
        padding: em(2) em(10) em(4);
      }

      @include media-breakpoint-down(sm) {
        min-height: em(34);
      }

      #{$parent} {
        &__font {
          @include media-breakpoint-down(lg) {
            font-size: em(11);
          }

          @include media-breakpoint-down(sm) {
            font-size: em(12);
          }
        }
      }
    }

    &--white-footer {
      padding: em(10) em(40) em(12);
      color: $color-white;
      border-color: $color-white;

      @include media-breakpoint-down(lg) {
        padding: em(10) em(25) em(10);
      }

      @include media-breakpoint-down(sm) {
        padding: em(10) em(50) em(10);
      }

      @include media-breakpoint-down(xs) {
        padding: em(10) em(36) em(10);
      }

      #{$parent} {
        &__font {
          @include media-breakpoint-down(lg) {
            font-size: em(12);
          }

          @include media-breakpoint-down(sm) {
            font-size: em(14);
          }
        }
      }
    }

    &--second {
      border: none;
    }

    &--second-white {
      color: $color-white;
      border: none;
    }

    &--small {
      min-height: em(34);
      padding: em(2) em(15) em(4);
    }

    &--link {
      min-height: auto;
      padding: 0;
      border: none;

      @include hover {
        background-color: transparent;
        opacity: 0.8;
      }

      #{$parent} {
        &__font {
          @include subtitle-s;

          @include media-breakpoint-down(lg) {
            font-size: em(7);
          }

          @include media-breakpoint-down(sm) {
            @include subtitle-s;
          }
        }
      }
    }

    &--link-big {
      min-height: auto;
      padding: 0;
      border: none;

      @include hover {
        background-color: transparent;
        opacity: 0.8;
      }

      #{$parent} {
        &__font {
          @include button-mob;

          letter-spacing: 0.2em;

          @include media-breakpoint-down(lg) {
            font-size: em(7);
          }

          @include media-breakpoint-down(sm) {
            @include button-mob;
          }
        }
      }
    }
  }
}
</style>
